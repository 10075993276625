<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Vehicles
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="
            $_checkPermission(this.auth.permissions, 'Manage Vehicle', 'Create')
          "
        >
          <v-icon left> mdi-plus </v-icon> New Vehicle
        </v-btn>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-text-field
          filled
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @change="modifyQuery('search', search)"
        ></v-text-field>
      </v-card-title>
      <v-card-title class="primary--text d-flex justify-end">
        <v-autocomplete
          auto-select-first
          style="max-width: 210px"
          filled
          multiple
          flat
          v-model="selectedCols"
          :items="allHeaders"
          item-text="text"
          item-value="id"
          label="Columns"
          outlined
          dense
          hide-details
          @change="updateHeadders"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="me-1">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedCols.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
        <v-btn
          class="ms-2 my-2"
          depressed
          color="green"
          dark
          @click="exportCSV"
        >
          <v-icon> mdi-file-export-outline </v-icon>
          <v-sheet color="transparent" class="d-none d-sm-block ms-2"
            >export csv</v-sheet
          >
        </v-btn>
        <v-btn
          class="ms-2 my-2"
          depressed
          color="primary"
          dark
          @click="exportPDF"
        >
          <v-icon> mdi-file-export-outline </v-icon>
          <v-sheet color="transparent" class="d-none d-sm-block ms-2"
            >export pdf</v-sheet
          >
        </v-btn>
      </v-card-title>
      <v-data-table
        id="imso-log-table"
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search="search"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0 mt-1"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
        <template v-slot:item.staff.first_name="{ item }">
          {{
            item.staff
              ? item.staff.first_name + " " + item.staff.last_name
              : "Not assign"
          }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  fetchAndStoreVehicles,
  syncOfflineVehiclesChanges,
} from "@/services/vehiclesService";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    newQuery: {},
    loading: false,
    search: "",
    selectedCols: [1, 2, 3, 4, 5, 6, 18],
    allHeaders: [
      {
        id: 1,
        text: "Vehicle No.",
        value: "vehicle_no",
      },
      {
        id: 2,
        text: "Category",
        value: "category",
      },
      {
        id: 3,
        text: "Model",
        value: "model",
      },
      {
        id: 4,
        text: "Make",
        value: "make",
      },
      {
        id: 5,
        text: "Class",
        value: "class",
      },
      {
        id: 6,
        text: "Driver",
        value: "staff.first_name",
      },
      {
        id: 7,
        text: "Engine No.",
        value: "engine_no",
      },
      {
        id: 8,
        text: "Chassis No.",
        value: "chassis_no",
      },
      {
        id: 9,
        text: "Registration Date",
        value: "registration_date",
      },
      {
        id: 10,
        text: "Oil Changed Date",
        value: "oil_changed_date",
      },
      {
        id: 11,
        text: "Oil Changed (km)",
        value: "oil_changed_km",
      },
      {
        id: 12,
        text: "Mechanical Serviced Date",
        value: "mechanical_serviced_date",
      },
      {
        id: 13,
        text: "Mechanical Serviced (km)",
        value: "mechanical_serviced_km",
      },
      {
        id: 14,
        text: "Current Meter Reading (km)",
        value: "current_meter_reading",
      },
      {
        id: 15,
        text: "Revenue License Date",
        value: "revenue_license_date",
      },
      {
        id: 16,
        text: "Insurance Date",
        value: "insurance_date",
      },
      {
        id: 17,
        text: "Emission Test Date",
        value: "emission_test_date",
      },
      { id: 18, text: "Action", value: "actions", sortable: false },
    ],
    headers: [
      {
        text: "Vehicle No.",
        align: "start",
        value: "vehicle_no",
      },
      { text: "Category", value: "category" },
      { text: "Model", value: "model" },
      { text: "Make", value: "make" },
      { text: "Class", value: "class" },
      { text: "Driver", value: "staff.first_name" },
      { text: "Action", value: "actions", sortable: false },
    ],
    items: [],
  }),
  async created() {
    if (
      this.$_checkPermission(this.auth.permissions, "Manage Vehicle", "Read")
    ) {
      this.loading = true;
      await syncOfflineVehiclesChanges();
      this.items = await fetchAndStoreVehicles();
      const query = this.$route.query;
      this.search = query.search || this.search;
      this.loading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      if (JSON.stringify(this.newQuery) != JSON.stringify(this.$route.query)) {
        this.$router.replace({ path: this.$route.path, query: this.newQuery });
      }
      this.$router.push({
        name: "PageViewVehicle",
        params: { uuid: item.uuid },
        query: { bc: item.vehicle_no },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewVehicle" });
    },
    updateHeadders() {
      this.headers = [];
      this.selectedCols.forEach((id) => {
        this.headers.push(this.allHeaders.find((element) => element.id == id));
      });
      this.headers.sort((a, b) => a.id - b.id);
      // this.headers.push({ text: "Action", value: "actions", sortable: false });
    },
    exportCSV() {
      const tableIds = ["imso-log-table"];
      const filename = "Vehicle List";

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
    exportPDF() {
      const tableIds = ["imso-log-table"];
      const filename = "Vehicle List";

      // Create a new jsPDF instance
      const doc = new jsPDF({ orientation: "landscape" });

      // Add header text
      const headerText = filename;
      doc.setFontSize(16);
      doc.text(headerText, 14, 20); // Position the header at the top-left of the page

      // Add an image to the top-right corner
      const imgData = require("@/assets/logo.png"); // Adjust the path to your image file
      const imgWidth = 55; // Image width
      const imgHeight = 20; // Image height
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the width of the page
      doc.addImage(
        imgData,
        "PNG",
        pageWidth - imgWidth - 10,
        5,
        imgWidth,
        imgHeight
      ); // Position image

      // Adjust table starting position
      let startY = 25; // Start below the header and image

      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");
          const tableData = [];

          rows.forEach((row) => {
            const cells = row.querySelectorAll("th, td");
            const rowData = [];

            cells.forEach((cell) => {
              // Get cell content and replace problematic characters
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");
              rowData.push(cellContent);
            });

            tableData.push(rowData);
          });

          // Add table content to the PDF
          doc.autoTable({
            head: [tableData[0]], // Use the first row as the header
            body: tableData.slice(1), // Remaining rows as body
            startY: startY, // Start below the header and image
            theme: "grid", // Grid styling for better readability
            headStyles: {
              fillColor: [8, 87, 110], // Custom background color for header (RGB format)
              textColor: [255, 255, 255], // White text color for contrast
            },
          });
        }
      });

      // Add footer content
      const footerText = `
This is a system-generated report. Generated at: ${new Date().toLocaleString()}. imso.openesrilanka.org`;

      const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of the page
      const footerLines = footerText.trim().split("\n");
      const fontSize = 10;
      doc.setFontSize(fontSize);

      // Align each line to the right
      footerLines.forEach((line, index) => {
        const textWidth = doc.getTextWidth(line);
        const xPosition = pageWidth - textWidth - 10; // 10 units margin from the right
        const yPosition = pageHeight - 10 + index * (fontSize - 5); // 40 units from the bottom, line spacing adjusted
        doc.text(line, xPosition, yPosition);
      });

      // Save the generated PDF
      doc.save(filename);
    },

    modifyQuery(queryKey, value) {
      // Create the new query object with updated value
      this.newQuery = { ...this.$route.query, [queryKey]: value || undefined };

      // Check if the query parameters have changed
      // const isSameQuery =
      //   JSON.stringify(newQuery) === JSON.stringify(this.$route.query);

      // If the query has not changed, do nothing
      // if (isSameQuery) {
      //   return;
      // }

      // Otherwise, update the query with replace (avoiding page reload)
      // this.$router.replace({ path: this.$route.path, query: newQuery });
    },
  },
};
</script>