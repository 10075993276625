<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" sm="6" lg="4">
            <v-row>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      dark
                      :to="{ name: 'PageNewVehicleRunning' }"
                      class="d-flex align-center justify-center pa-3 lime"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-counter </v-icon>
                        New Vehicle Running Log
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      dark
                      :to="{
                        name: 'PageMyVehicleRepairList',
                        query: { new: 'true' },
                      }"
                      class="d-flex align-center justify-center pa-3 orange"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-wrench </v-icon>
                        New Vehicle Repair Log
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      dark
                      :to="{
                        name: 'PageMyVehicleFuelList',
                        query: { new: 'true' },
                      }"
                      class="d-flex align-center justify-center pa-3 teal"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-gas-station </v-icon>
                        New Vehicle Fuel Log
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card flat outlined>
                  <v-responsive :aspect-ratio="1 / 1">
                    <v-list-item
                      link
                      :to="{ name: 'PageNewLeaveApplication' }"
                      dark
                      class="d-flex align-center justify-center pa-3 red"
                    >
                      <v-sheet
                        color="transparent"
                        class="d-flex flex-column align-center text-center"
                      >
                        <v-icon large> mdi-calendar </v-icon>
                        New Leave Application
                      </v-sheet>
                    </v-list-item>
                  </v-responsive>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-row>
              <v-col cols="12">
                <v-card flat outlined>
                  <v-list-item dark class="text-h6 purple">
                    Your Projects
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-sheet
                    color="transparent"
                    v-for="project in myProjects"
                    :key="project.uuid"
                  >
                    <v-divider></v-divider>
                    <v-list-item @click="viewProject(project)">
                      {{ project.code }} - {{ project.name }}
                    </v-list-item>
                  </v-sheet>
                  <v-sheet color="transparent" v-if="myProjects.length == 0">
                    <v-divider></v-divider>
                    <v-list-item> No Projects.</v-list-item>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card flat outlined>
                  <v-list-item dark class="text-h6 green">
                    Your Vehicles
                  </v-list-item>
                  <v-sheet
                    color="transparent"
                    v-for="vehicle in myVehicles"
                    :key="vehicle.uuid"
                  >
                    <v-divider></v-divider>
                    <v-list-item @click="viewVehicle(vehicle)">
                      {{ vehicle.vehicle_no }}
                      <v-chip small class="ms-1">
                        {{ vehicle.class }}
                      </v-chip>
                      <v-chip small class="ms-1">
                        {{ vehicle.category }}
                      </v-chip>
                    </v-list-item>
                    <v-sheet color="transparent" class="pa-2">
                      <v-chip
                        class="ma-1"
                        small
                        v-if="
                          vehicle.current_meter_reading >
                          vehicle.oil_changed_km +
                            vehicle.oil_change_interval_km -
                            vehicle.oil_change_notification_km
                        "
                        :color="
                          vehicle.current_meter_reading >
                          vehicle.oil_changed_km +
                            vehicle.oil_change_interval_km
                            ? 'error'
                            : 'warning'
                        "
                      >
                        <v-icon small class="me-1"> mdi-alert-outline </v-icon>
                        <span>
                          {{
                            vehicle.current_meter_reading >
                            vehicle.oil_changed_km +
                              vehicle.oil_change_interval_km
                              ? `Oil change overdue. Act now.`
                              : `Oil change due before ${
                                  vehicle.mechanical_serviced_km +
                                  vehicle.mechanical_service_interval_km
                                } km.`
                          }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        small
                        v-if="
                          vehicle.current_meter_reading >
                          vehicle.mechanical_serviced_km +
                            vehicle.mechanical_service_interval_km -
                            vehicle.mechanical_service_notification_km
                        "
                        :color="
                          vehicle.current_meter_reading >
                          vehicle.mechanical_serviced_km +
                            vehicle.mechanical_service_interval_km
                            ? 'error'
                            : 'warning'
                        "
                      >
                        <v-icon small class="me-1"> mdi-alert-outline </v-icon>
                        <span>
                          {{
                            vehicle.current_meter_reading >
                            vehicle.mechanical_serviced_km +
                              vehicle.mechanical_service_interval_km
                              ? `Service overdue. Act now.`
                              : `Service due before ${
                                  vehicle.mechanical_serviced_km +
                                  vehicle.mechanical_service_interval_km
                                } km.`
                          }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        small
                        v-if="
                          $_daysDifference(vehicle.revenue_license_date) < 30
                        "
                        :color="
                          $_daysDifference(vehicle.revenue_license_date) > 0
                            ? 'warning'
                            : 'error'
                        "
                      >
                        <v-icon small class="me-1"> mdi-alert-outline </v-icon>
                        <span>
                          {{
                            $_daysDifference(vehicle.revenue_license_date) > 0
                              ? `Revenue license expires in ${$_daysDifference(
                                  vehicle.revenue_license_date
                                )} days.`
                              : `Revenue license expired.`
                          }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        small
                        v-if="$_daysDifference(vehicle.insurance_date) < 30"
                        :color="
                          $_daysDifference(vehicle.insurance_date) > 0
                            ? 'warning'
                            : 'error'
                        "
                      >
                        <v-icon small class="me-1"> mdi-alert-outline </v-icon>
                        <span>
                          {{
                            $_daysDifference(vehicle.insurance_date) > 0
                              ? `Insurance expires in ${$_daysDifference(
                                  vehicle.insurance_date
                                )} days.`
                              : `Insurance expired.`
                          }}
                        </span>
                      </v-chip>
                      <v-chip
                        class="ma-1"
                        small
                        v-if="$_daysDifference(vehicle.emission_test_date) < 30"
                        :color="
                          $_daysDifference(vehicle.emission_test_date) > 0
                            ? 'warning'
                            : 'error'
                        "
                      >
                        <v-icon small class="me-1"> mdi-alert-outline </v-icon>
                        <span>
                          {{
                            $_daysDifference(vehicle.emission_test_date) > 0
                              ? `Emission test due in ${$_daysDifference(
                                  vehicle.emission_test_date
                                )} days.`
                              : `Emission test expired.`
                          }}
                        </span>
                      </v-chip>
                    </v-sheet>
                  </v-sheet>
                  <v-sheet color="transparent" v-if="myVehicles.length == 0">
                    <v-divider></v-divider>
                    <v-list-item> No Vehicles.</v-list-item>
                  </v-sheet>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="5">
            <v-card
              class="d-flex flex-column align-center pa-6 blue text-center"
              flat
              light
            >
              <!-- <div class="secondary--text mb-5">
                <v-icon color="secondary" small>mdi-tools</v-icon>
                app under construction
              </div> -->

              <div
                color="transparent"
                class="text-h1 d-flex align-center"
                style="font-weight: 900; color: #07617b"
              >
                IMS
                <v-img max-width="80" alt="IMSO" src="/images/Icon.png" />
              </div>

              Information Management System of OPEnE

              <v-row class="mt-5">
                <v-col>
                  <v-card flat outlined height="100%" class="wraperx">
                    <v-sheet height="100%" color="transparent" dark class="pa-3 teal">
                      <v-sheet
                        color="transparent"
                        class="d-flex align-start justify-space-between"
                      >
                        <v-icon x-large> mdi-briefcase </v-icon>
                        <v-sheet color="transparent" class="text-right">
                          <v-sheet color="transparent" class="text-h3">
                            {{ dashboardData.project_count }}
                          </v-sheet>
                          Total Projects
                        </v-sheet>
                      </v-sheet>
                    </v-sheet>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat outlined height="100%" class="wraperx">
                    <v-sheet height="100%" color="transparent" dark class="pa-3 lime">
                      <v-sheet
                        color="transparent"
                        class="d-flex align-start justify-space-between"
                      >
                        <v-icon x-large> mdi-account-multiple </v-icon>
                        <v-sheet color="transparent" class="text-right">
                          <v-sheet color="transparent" class="text-h3">
                            {{ dashboardData.staff_count }}
                          </v-sheet>
                          Total Active Staff
                        </v-sheet>
                      </v-sheet>
                    </v-sheet>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat outlined height="100%" class="wraperx">
                    <v-sheet height="100%" dark color="transparent" class="pa-3 purple">
                      <v-sheet
                        color="transparent"
                        class="d-flex align-start justify-space-between"
                      >
                        <v-icon x-large> mdi-car-multiple </v-icon>
                        <v-sheet color="transparent" class="text-right">
                          <v-sheet color="transparent" class="text-h3">
                            {{ dashboardData.vehicle_count }}
                          </v-sheet>
                          Total Vehicles
                        </v-sheet>
                      </v-sheet>
                    </v-sheet>
                  </v-card>
                </v-col>
              </v-row>

              <!-- <v-date-picker
                no-title
                class="mt-5"
                color="primary"
              ></v-date-picker> -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  fetchAndStoreMyVehicles,
  fetchAndStoreMyProjects,
} from "@/services/myDataService";

import { fetchAndStoreDashboardData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    breadcrumbsItems: [
      {
        text: "Dashboard",
        disabled: true,
        href: "",
      },
    ],
    myProjects: [],
    myVehicles: [],
    loading: false,
    dashboardData: {},
  }),
  async created() {
    this.loading = true;
    this.myProjects = await fetchAndStoreMyProjects();
    this.myVehicles = await fetchAndStoreMyVehicles();
    this.dashboardData = await fetchAndStoreDashboardData();
    // console.log(this.dashboardData)
    this.loading = false;
  },
  mounted() {
    //
  },
  methods: {
    viewProject(item) {
      this.$router.push({
        name: "PageViewProject",
        params: { uuid: item.uuid },
        query: { bc: item.name },
      });
    },
    viewVehicle(item) {
      this.$router.push({
        name: "PageViewVehicle",
        params: { uuid: item.uuid },
        query: { bc: item.vehicle_no },
      });
    },
  },
};
</script>

<style scoped>
.lime {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%); */
  background-image: linear-gradient(
    -225deg,
    #f4d03f 0%,
    #16a074 78%,
    #16a074 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.lime:hover {
  background-position: right center;
}

.orange {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%); */
  background-image: linear-gradient(
    -225deg,
    #f0a119 0%,
    #f53f27 88%,
    #f53f27 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.orange:hover {
  background-position: right center;
}

.teal {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%); */
  background-image: linear-gradient(
    -225deg,
    #0093e9 0%,
    #0093e9 18%,
    #80d0c7 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.teal:hover {
  background-position: right center;
}

.red {
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(-60deg, #ff0844 0%, #ffb199 100%); */
  background-image: linear-gradient(
    -225deg,
    #ffb199 0%,
    #ffb199 18%,
    #ff0844 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.red:hover {
  background-position: right center;
}

.purple {
  background-image: linear-gradient(
    -225deg,
    #5271c4 0%,
    #b19fff 48%,
    #eca1fe 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
}
.purple:hover {
  background-position: right center;
}

.green {
  background-image: linear-gradient(60deg, #64b3f4 0%, #c2e59c 100%);
  transition: 0.5s;
  background-size: 200% auto;
}
.green:hover {
  background-position: right center;
}

.blue {
  background-image: linear-gradient(to top, #89afda 0%, #e7f0fd 100%);
}
</style>