<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table-heading, list-item"></v-skeleton-loader>
      <v-row class="mx-2">
        <v-col cols="12" lg="3" md="4" sm="6" v-for="i in 8" :key="i">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-skeleton-loader type="table-tfoot"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Staff
        <v-divider vertical class="mx-3"> </v-divider>

        <v-btn
          icon
          color="primary"
          dark
          @click="
            $router.push({
              name: 'PageStaffList',
            })
          "
        >
          <v-icon> mdi-view-grid-outline </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="newItem()"
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Create')"
        >
          <v-icon left> mdi-plus </v-icon> New Staff
        </v-btn>
      </v-card-title>
      <v-card-title class="justify-center justify-sm-start">
        <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="filterData"
            background-color="transparent"
          >
            <v-tab :tab-value="1">All</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="2">Current</v-tab>
            <v-divider vertical></v-divider>
            <v-tab :tab-value="3">Past</v-tab>
          </v-tabs>
        </v-card>
      </v-card-title>

      <v-card-title class="primary--text">
        <v-text-field
          dense
          filled
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @change="modifyQuery('search', search)"
        ></v-text-field>
        <v-menu
          :close-on-content-click="true"
          :nudge-width="200"
          offset-y
          bottom
          max-width="180"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="ms-2">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>

          <v-card outlined elevation="0" class="imso-menu">
            <v-list class="pa-0">
              <v-list-item
                link
                @click="
                  status = 1;
                  filterData();
                "
              >
                <v-list-item-action>
                  <v-checkbox v-model="status" dense :value="1"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                link
                @click="
                  status = 2;
                  filterData();
                "
              >
                <v-list-item-action>
                  <v-checkbox v-model="status" dense :value="2"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>Active</v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                link
                @click="
                  status = 3;
                  filterData();
                "
              >
                <v-list-item-action>
                  <v-checkbox v-model="status" dense :value="3"></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>Deactivated</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-card-title>

      <v-card-title class="primary--text d-flex justify-end">
        <v-autocomplete
          auto-select-first
          style="max-width: 200px"
          filled
          multiple
          flat
          v-model="selectedCols"
          :items="allHeaders"
          item-text="text"
          item-value="id"
          label="Columns"
          outlined
          dense
          hide-details
          @change="updateHeadders"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="me-1">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedCols.length - 1 }} others)
            </span>
          </template>
        </v-autocomplete>
        <v-btn
          class="ms-2 my-2"
          depressed
          color="green"
          dark
          @click="exportCSV"
        >
          <v-icon> mdi-file-export-outline </v-icon>
          <v-sheet color="transparent" class="d-none d-sm-block ms-2"
            >export csv</v-sheet
          >
        </v-btn>
        <v-btn
          class="ms-2 my-2"
          depressed
          color="primary"
          dark
          @click="exportPDF"
        >
          <v-icon> mdi-file-export-outline </v-icon>
          <v-sheet color="transparent" class="d-none d-sm-block ms-2"
            >export pdf</v-sheet
          >
        </v-btn>
      </v-card-title>

      <v-data-table
        id="imso-log-table"
        :loading="loading"
        mobile-breakpoint="0"
        :headers="headers"
        :items="data"
        :search="search"
      >
        <template v-slot:item.first_name="{ item }">
          <v-sheet color="transparent">
            {{ item.first_name + " " + item.last_name }}
          </v-sheet>
        </template>
        <template v-slot:item.address_1="{ item }">
          <v-sheet color="transparent">
            {{
              item.address_1 +
              ", " +
              item.address_2 +
              ", " +
              item.city +
              ", " +
              item.state +
              ", " +
              item.country +
              ". " +
              item.postal_code
            }}
          </v-sheet>
        </template>
        <template v-slot:item.staff="{ item }">
          <v-sheet color="transparent" v-if="item.staff">
            {{ item.staff.first_name + " " + item.staff.last_name }}
          </v-sheet>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            depressed
            small
            color="primary"
            class="mx-0 px-0"
            dark
            @click="viewItem(item)"
          >
            more
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import {
  fetchAndStoreStaff,
  syncOfflineStaffChanges,
} from "@/services/staffService";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    newQuery: {},
    loading: false,
    search: "",
    selectedCols: [1, 3, 4, 5, 6, 21],
    allHeaders: [
      {
        id: 1,
        text: "Staff No",
        value: "staff_no",
      },
      {
        id: 2,
        text: "EPF No.",
        value: "epf_no",
      },
      {
        id: 3,
        text: "Full Name",
        value: "first_name",
      },
      {
        id: 4,
        text: "Designation",
        value: "designation",
      },
      {
        id: 5,
        text: "Office Email",
        value: "primary_email",
      },
      {
        id: 6,
        text: "Personal Mobile",
        value: "primary_mobile_no",
      },
      {
        id: 7,
        text: "Personal Email",
        value: "secondary_email",
      },
      {
        id: 8,
        text: "NIC No.",
        value: "nic_no",
      },
      {
        id: 9,
        text: "Staff Type",
        value: "type",
      },
      {
        id: 10,
        text: "Immediate Supervisor",
        value: "staff",
      },
      {
        id: 11,
        text: "Joined date",
        value: "date_of_joining",
      },
      {
        id: 12,
        text: "Age at Joining",
        value: "age_at_joining",
      },
      {
        id: 13,
        text: "Secondary Mobile",
        value: "secondary_mobile_no",
      },
      {
        id: 14,
        text: "Gender",
        value: "gender",
      },
      {
        id: 15,
        text: "Date of Birth",
        value: "date_of_birth",
      },
      {
        id: 16,
        text: "Blood Group",
        value: "blood_group",
      },
      {
        id: 17,
        text: "Address",
        value: "address_1",
      },
      {
        id: 18,
        text: "Left Date",
        value: "left_date",
      },
      {
        id: 19,
        text: "Contract Start Date",
        value: "contract_start_date",
      },
      {
        id: 20,
        text: "Contract End Date",
        value: "contract_end_date",
      },
      { id: 21, text: "Action", value: "actions", sortable: false },
    ],
    headers: [
      {
        text: "Staff No",
        align: "start",
        value: "staff_no",
      },
      {
        text: "Full Name",
        value: "first_name",
      },
      { text: "Designation", value: "designation" },
      { text: "Office Email", value: "primary_email" },
      { text: "Personal Mobile", value: "primary_mobile_no" },
      { text: "Action", value: "actions", sortable: false },
    ],
    data: [],
    dataX: [],
    tab: 2,
    status: 1,
  }),
  async created() {
    if (this.$_checkPermission(this.auth.permissions, "Manage Staff", "Read")) {
      this.loading = true;
      await syncOfflineStaffChanges();
      this.dataX = await fetchAndStoreStaff();
      this.filterData();
      const query = this.$route.query;
      this.search = query.search || this.search;
      this.loading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    viewItem(item) {
      if (JSON.stringify(this.newQuery) != JSON.stringify(this.$route.query)) {
        this.$router.replace({ path: this.$route.path, query: this.newQuery });
      }
      this.$router.push({
        name: "PageViewStaff",
        params: { uuid: item.uuid },
        query: { bc: item.first_name + " " + item.last_name },
      });
    },
    newItem() {
      this.$router.push({ name: "PageNewStaff" });
    },
    copyToClipboard(text) {
      this.$store.commit("setError", {
        isError: false,
        error: "Copied to clipboard!",
      });
      navigator.clipboard.writeText(text);
    },
    filterData() {
      this.data = [];

      if (this.tab == 3) {
        this.dataX.forEach((staff) => {
          if (staff.left_date) {
            if (this.status == 3) {
              if (!staff.is_active) this.data.push(staff);
            } else if (this.status == 2) {
              if (staff.is_active) this.data.push(staff);
            } else {
              this.data.push(staff);
            }
          }
        });
      } else if (this.tab == 2) {
        this.dataX.forEach((staff) => {
          if (!staff.left_date) {
            if (this.status == 3) {
              if (!staff.is_active) this.data.push(staff);
            } else if (this.status == 2) {
              if (staff.is_active) this.data.push(staff);
            } else {
              this.data.push(staff);
            }
          }
        });
      } else {
        this.dataX.forEach((staff) => {
          if (this.status == 3) {
            if (!staff.is_active) this.data.push(staff);
          } else if (this.status == 2) {
            if (staff.is_active) this.data.push(staff);
          } else {
            this.data.push(staff);
          }
        });
      }
    },
    updateHeadders() {
      this.headers = [];
      this.selectedCols.forEach((id) => {
        this.headers.push(this.allHeaders.find((element) => element.id == id));
      });
      this.headers.sort((a, b) => a.id - b.id);
      // this.headers.push({ text: "Action", value: "actions", sortable: false });
    },
    exportCSV() {
      const tableIds = ["imso-log-table"];
      const filename = "Staff List";

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },

    exportPDF() {
      const tableIds = ["imso-log-table"];
      const filename = "Staff List";

      // Create a new jsPDF instance
      const doc = new jsPDF({ orientation: "landscape" });

      // Add header text
      const headerText = filename;
      doc.setFontSize(16);
      doc.text(headerText, 14, 20); // Position the header at the top-left of the page

      // Add an image to the top-right corner
      const imgData = require("@/assets/logo.png"); // Adjust the path to your image file
      const imgWidth = 55; // Image width
      const imgHeight = 20; // Image height
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the width of the page
      doc.addImage(
        imgData,
        "PNG",
        pageWidth - imgWidth - 10,
        5,
        imgWidth,
        imgHeight
      ); // Position image

      // Adjust table starting position
      let startY = 25; // Start below the header and image

      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");
          const tableData = [];

          rows.forEach((row) => {
            const cells = row.querySelectorAll("th, td");
            const rowData = [];

            cells.forEach((cell) => {
              // Get cell content and replace problematic characters
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");
              rowData.push(cellContent);
            });

            tableData.push(rowData);
          });

          // Add table content to the PDF
          doc.autoTable({
            head: [tableData[0]], // Use the first row as the header
            body: tableData.slice(1), // Remaining rows as body
            startY: startY, // Start below the header and image
            theme: "grid", // Grid styling for better readability
            headStyles: {
              fillColor: [8, 87, 110], // Custom background color for header (RGB format)
              textColor: [255, 255, 255], // White text color for contrast
            },
          });
        }
      });

      // Add footer content
      const footerText = `
This is a system-generated report. Generated at: ${new Date().toLocaleString()}. imso.openesrilanka.org`;

      const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of the page
      const footerLines = footerText.trim().split("\n");
      const fontSize = 10;
      doc.setFontSize(fontSize);

      // Align each line to the right
      footerLines.forEach((line, index) => {
        const textWidth = doc.getTextWidth(line);
        const xPosition = pageWidth - textWidth - 10; // 10 units margin from the right
        const yPosition = pageHeight - 10 + index * (fontSize - 5); // 40 units from the bottom, line spacing adjusted
        doc.text(line, xPosition, yPosition);
      });

      // Save the generated PDF
      doc.save(filename);
    },

    modifyQuery(queryKey, value) {
      // Create the new query object with updated value
      this.newQuery = { ...this.$route.query, [queryKey]: value || undefined };

      // Check if the query parameters have changed
      // const isSameQuery =
      //   JSON.stringify(newQuery) === JSON.stringify(this.$route.query);

      // If the query has not changed, do nothing
      // if (isSameQuery) {
      //   return;
      // }

      // Otherwise, update the query with replace (avoiding page reload)
      // this.$router.replace({ path: this.$route.path, query: newQuery });
    },
  },
};
</script>