<template>
  <v-container fluid>
    <v-card flat outlined class="pa-2 pa-sm-6">
      <v-sheet class="mb-5 text-h6 d-flex align-center" color="transparent">
        <v-btn icon class="me-2" @click="$router.go(-1)">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        Staff Information
      </v-sheet>
      <v-sheet
        class="d-md-flex align-center justify-space-around"
        color="transparent"
      >
        <v-sheet
          class="d-flex flex-column align-center ma-6"
          color="transparent"
        >
          <v-sheet
            v-if="!loading"
            width="100%"
            class="mb-2 d-flex justify-center"
            color="transparent"
          >
            <v-chip
              dark
              class="mx-1"
              :color="staff.is_active ? 'green' : 'red'"
              x-small
            >
              {{ staff.is_active ? "Active" : "Deactivated" }}
            </v-chip>
            <v-chip
              dark
              class="mx-1"
              :color="staff.left_date ? 'orange' : 'blue'"
              x-small
            >
              {{ staff.left_date ? "Past Staff" : "Current Staff" }}
            </v-chip>
          </v-sheet>

          <v-avatar size="200" v-if="loading">
            <v-skeleton-loader width="200" type="image"></v-skeleton-loader>
          </v-avatar>
          <v-avatar
            v-else-if="staff"
            :color="'#' + ((Math.random() * 0xffffff) << 0).toString(16)"
            size="200"
            class="mr-0 d-flex justify-center"
            style="position: relative"
          >
            <div class="white--text text-h1" style="position: absolute">
              {{ staff.first_name.charAt(0).toUpperCase()
              }}{{ staff.last_name.charAt(0).toUpperCase() }}
            </div>
            <v-img
              :src="staff.profile"
              alt="staff.first_name"
              v-if="staff.profile && !newProfile"
            />
            <v-img v-if="newProfile" :src="newProfile"></v-img>
          </v-avatar>
          <input
            type="file"
            ref="fileInput"
            @change="previewImage"
            style="display: none"
            accept="image/*"
          />
          <v-btn
            color="primary"
            depressed
            class="mt-3"
            small
            @click="selectImage"
            v-if="!loading && staff && auth.uuid == staff.uuid && !newProfile"
          >
            Change Picture
          </v-btn>
          <v-sheet color="transparent" v-if="newProfile">
            <v-btn text small class="ma-3" @click="newProfile = null">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              depressed
              class="ma-3"
              small
              @click="uploadProfile"
              :loading="updateProfileLoading"
            >
              Save
            </v-btn>
          </v-sheet>
        </v-sheet>
        <v-row class="ms-md-12" v-if="loading">
          <v-col cols="12" sm="6" class="pb-0 pb-md-3" v-for="i in 8" :key="i">
            <v-skeleton-loader type="sentences"></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="ms-md-12" v-else-if="staff">
          <v-col cols="12" sm="6" class="pb-0 pb-md-3">
            <v-sheet
              color="transparent text--primary"
              style="font-weight: bold"
              class="text-h5 text-center text-sm-left"
            >
              {{ staff.first_name }} {{ staff.last_name }}
            </v-sheet>
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-account-star-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ staff.designation }}</v-list-item-title>
                <v-list-item-subtitle>Designation</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  $_getHistory(staff.staff_history, 'UP_DESIG', 'UP_JOIN')
                    .length > 0
                "
              >
                <v-btn
                  icon
                  @click="
                    history = $_getHistory(
                      staff.staff_history,
                      'UP_DESIG',
                      'UP_JOIN'
                    );
                    historyDialog = true;
                    history_type = 'UP_DESIG';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-email-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ staff.primary_email }}</v-list-item-title>
                <v-list-item-subtitle>Office Email</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                v-if="$_getHistory(staff.staff_history, 'UP_EMAIL').length > 0"
              >
                <v-btn
                  icon
                  @click="
                    history = $_getHistory(staff.staff_history, 'UP_EMAIL');
                    historyDialog = true;
                    history_type = 'UP_EMAIL';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-phone </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  staff.primary_mobile_no
                }}</v-list-item-title>
                <v-list-item-subtitle>Personal Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              two-line
              v-if="
                $_checkPermission(auth.permissions, 'Manage Staff', 'Delete') ||
                $_checkPermission(auth.permissions, 'Manage Staff', 'Update') ||
                $_checkPermission(auth.permissions, 'Manage Staff', 'Create')
              "
            >
              <v-list-item-icon>
                <v-icon color="primary"> mdi-id-card </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ staff.nic_no }}</v-list-item-title>
                <v-list-item-subtitle>Passport/NIC No.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" class="pt-0 pt-md-3">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-map-marker-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  >{{ staff.address_1 }},
                  {{ staff.address_2 }},</v-list-item-title
                >
                <v-list-item-title
                  >{{ staff.city }}, {{ staff.postal_code }},</v-list-item-title
                >
                <v-list-item-title
                  >{{ staff.state }}, {{ staff.country }}.</v-list-item-title
                >
                <v-list-item-subtitle> Address </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-gender-female </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ staff.gender }}</v-list-item-title>
                <v-list-item-subtitle>Gender</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-month-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ staff.date_of_birth }}</v-list-item-title>
                <v-list-item-subtitle>Date of Birth</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-water-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ staff.blood_group }}</v-list-item-title>
                <v-list-item-subtitle>Blood Group</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        $_checkPermission(auth.permissions, 'Manage Staff', 'Delete') ||
        $_checkPermission(auth.permissions, 'Manage Staff', 'Update') ||
        $_checkPermission(auth.permissions, 'Manage Staff', 'Create')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent">
        Administrative Information
      </v-sheet>
      <v-row class="ms-md-12" v-if="loading">
        <v-col cols="12" sm="6" md="4" v-for="i in 6" :key="i + 8">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="staff">
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-account-box-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.staff_no }}</v-list-item-title>
              <v-list-item-subtitle>Staff No.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-account-card-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.epf_no ?? "N/A" }}</v-list-item-title>
              <v-list-item-subtitle>EPF No.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-account-child-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                staff.staff
                  ? staff.staff.first_name + " " + staff.staff.last_name
                  : "N/A"
              }}</v-list-item-title>
              <v-list-item-subtitle>Immediate Supervisor</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="$_getHistory(staff.staff_history, 'UP_IMSUP').length > 0"
            >
              <v-btn
                icon
                @click="
                  history = $_getHistory(staff.staff_history, 'UP_IMSUP');
                  historyDialog = true;
                  history_type = 'UP_IMSUP';
                "
              >
                <v-icon color="grey lighten-1">mdi-history</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-shield-account-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.roles.name }}</v-list-item-title>
              <v-list-item-subtitle>IMSO Role</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="$_getHistory(staff.staff_history, 'UP_ROLE').length > 0"
            >
              <v-btn
                icon
                @click="
                  history = $_getHistory(staff.staff_history, 'UP_ROLE');
                  historyDialog = true;
                  history_type = 'UP_ROLE';
                "
              >
                <v-icon color="grey lighten-1">mdi-history</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-shape-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.type ?? "N/A" }}</v-list-item-title>
              <v-list-item-subtitle>Staff Type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-calendar-star-four-points </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.date_of_joining }}</v-list-item-title>
              <v-list-item-subtitle>Joined Date</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="$_getHistory(staff.staff_history, 'UP_JOIN').length > 0"
            >
              <v-btn
                icon
                @click="
                  history = $_getHistory(staff.staff_history, 'UP_JOIN');
                  historyDialog = true;
                  history_type = 'UP_JOIN';
                "
              >
                <v-icon color="grey lighten-1">mdi-history</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-calendar-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.age_at_joining }}</v-list-item-title>
              <v-list-item-subtitle>Age at Joining</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-calendar-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                staff.contract_start_date ?? "N/A"
              }}</v-list-item-title>
              <v-list-item-subtitle>Contract Start Date</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="$_getHistory(staff.staff_history, 'UP_CONTR').length > 0"
            >
              <v-btn
                icon
                @click="
                  history = $_getHistory(staff.staff_history, 'UP_CONTR');
                  historyDialog = true;
                  history_type = 'UP_CONTR';
                "
              >
                <v-icon color="grey lighten-1">mdi-history</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-calendar-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                staff.contract_end_date ?? "N/A"
              }}</v-list-item-title>
              <v-list-item-subtitle>Contract End Date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="py-0" v-if="staff.left_date">
          <v-list-item two-line>
            <v-list-item-icon>
              <v-icon color="primary"> mdi-calendar-remove </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ staff.left_date }}</v-list-item-title>
              <v-list-item-subtitle>Left Date</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        $_checkPermission(auth.permissions, 'Manage Staff Document', 'Read')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent"> Documents </v-sheet>
      <v-row class="ms-md-12" v-if="docGetLoading">
        <v-col cols="12" sm="6" md="4" v-for="i in 6" :key="i + 30">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="documets && documets.length > 0">
        <v-col cols="12" sm="6" md="4" v-for="doc in documets" :key="doc.uuid">
          <v-card outlined color="">
            <v-list-item two-line>
              <v-list-item-action>
                <v-btn icon x-large>
                  <v-icon x-large color="primary"
                    >mdi-file-document-outline</v-icon
                  >
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ doc.name }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $_formatTimestamp(doc.modified_at) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu
                  :close-on-content-click="true"
                  :nudge-width="200"
                  :max-width="200"
                  offset-x
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1">
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-card outlined elevation="0" class="imso-menu">
                    <v-list>
                      <v-list-item
                        link
                        @click="
                          selectedDoc = doc;
                          docViewDialog = true;
                        "
                      >
                        <v-list-item-title>Open</v-list-item-title>
                        <v-icon color="grey lighten-1">
                          mdi-open-in-new
                        </v-icon>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="downloadBase64File(doc.binary_file, doc.name)"
                      >
                        <v-list-item-title>Download</v-list-item-title>
                        <v-icon color="grey lighten-1"> mdi-download </v-icon>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="
                          selectedDoc = doc;
                          deleteDocDialog = true;
                        "
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                        <v-icon color="error"> mdi-delete </v-icon>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="text-center text--secondary">
        <v-col> No documents. </v-col>
      </v-row>
    </v-card>

    <v-card
      outlined
      flat
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        $_checkPermission(auth.permissions, 'Manage Staff', 'Delete') ||
        $_checkPermission(auth.permissions, 'Manage Staff', 'Update') ||
        $_checkPermission(auth.permissions, 'Manage Staff Document', 'Create')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent">
        Administrator Actions
      </v-sheet>
      <v-row v-if="loading">
        <v-col cols="12" v-for="i in 8" :key="i + 14">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list color="actionList" v-else>
        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Edit Staff</v-list-item-title>
            <v-list-item-subtitle>
              Edit staff information in the IMSO database.
              <span class="d-none d-md-inline">
                This action will not maintain a history of changes, suitable for
                correcting mistakes or when history is not required.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn depressed color="primary" outlined @click="editItem(staff)">
              <v-icon left> mdi-account-edit </v-icon>
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Staff Document',
              'Create'
            )
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Staff Document',
              'Create'
            )
          "
        >
          <v-list-item-content>
            <v-list-item-title>Upload Documents</v-list-item-title>
            <v-list-item-subtitle>
              Add or update documents related to the staff member.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="docUploadDialog = true"
            >
              <v-icon left>mdi-upload</v-icon>
              Upload
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Change App Role</v-list-item-title>
            <v-list-item-subtitle>
              Modify the staff member's role within the app.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn depressed color="secondary" outlined @click="openRoleChange">
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Update Office Email</v-list-item-title>
            <v-list-item-subtitle>
              Change the office email address used for signing in and other
              official purposes.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                updateEmailDialog = true;
                oldValue = staff.primary_email;
              "
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Update Designation</v-list-item-title>
            <v-list-item-subtitle>
              Change the designation ot the staff.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                updateDesignationDialog = true;
                oldValue = staff.designation;
              "
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Update Immediate Supervisor</v-list-item-title>
            <v-list-item-subtitle>
              Change the staff member's immediate supervisor.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="openSupervisorChange"
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Update Contract</v-list-item-title>
            <v-list-item-subtitle>
              Change the staff member's Contract.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="staffContractDialog = true"
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            staff.left_date &&
            $_checkPermission(auth.permissions, 'Manage Staff', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Rejoin Staff</v-list-item-title>
            <v-list-item-subtitle>
              Update the status of a returning staff member to restore their
              access to IMSO.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="staffRejoinDialog = true"
            >
              <v-icon left>mdi-login</v-icon>
              Rejoin
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          three-line
          v-else-if="
            $_checkPermission(auth.permissions, 'Manage Staff', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Archive a Staff</v-list-item-title>
            <v-list-item-subtitle>
              Update the status of a staff member who has left the organization.
              <span class="d-none d-md-inline">
                This prevents further access to IMSO. The history of changes
                will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="staffLeftDialog = true"
            >
              <v-icon left>mdi-logout</v-icon>
              Archive
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            staff.is_active &&
            $_checkPermission(auth.permissions, 'Manage Staff', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Deactivate User</v-list-item-title>
            <v-list-item-subtitle>
              Deactivate a user to prevent them from signing in and accessing
              IMSO.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deactivateStaffDialog = true"
            >
              <v-icon left>mdi-account-remove</v-icon>
              Deactivate
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          three-line
          v-else-if="
            $_checkPermission(auth.permissions, 'Manage Staff', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Activate User</v-list-item-title>
            <v-list-item-subtitle>
              Activate a user to allow them to sign in and access IMSO.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="activateStaffDialog = true"
            >
              <v-icon left>mdi-account-reactivate</v-icon>
              Activate
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Delete')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Staff', 'Delete')"
        >
          <v-list-item-content>
            <v-list-item-title>Delete Staff</v-list-item-title>
            <v-list-item-subtitle>
              Delete staff information from the app.
              <span class="d-none d-md-inline">
                This action cannot be undone through the app, and only a
                database admin can restore it. All related data will be hidden
                from the app.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deleteStaffDialog = true"
            >
              <v-icon left>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- Delete staff confirmation dialog -->
    <v-dialog v-model="deleteStaffDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this Staff? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteStaffX()"
            :loading="deleteStaffLoading"
          >
            Delete Staff
          </v-btn>
          <v-btn color="primary" text @click="deleteStaffDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- File Upload dialog -->
    <v-dialog v-model="docUploadDialog" persistent max-width="480">
      <v-card outlined>
        <v-card-title class="text-h5"> Upload Document </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="docForm">
            <v-text-field
              flat
              outlined
              label="Title"
              v-model="docUp.name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              outlined
              label="Description"
              flat
              v-model="docUp.description"
              :rules="[rules.required]"
            ></v-textarea>
            <v-file-input
              v-model="docUp.file"
              ref="docUpI"
              outlined
              show-size
              label="Upload File"
              :rules="[rules.required, rules.fileSize]"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="docUploadDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="uploadFile"
            :loading="docAddLoading"
          >
            Uppload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- view document -->
    <v-dialog
      v-model="docViewDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ma-0 pa-0"
    >
      <v-card
        height="100%"
        v-if="selectedDoc"
        tile
        class="ma-0 pa-0"
        color="primary"
      >
        <v-toolbar dark flat height="50">
          <v-toolbar-title>{{ selectedDoc.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="docViewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe
          :src="selectedDoc.binary_file"
          style="
            width: 100%;
            height: calc(100% - 60px);
            border: none;
            margin: auto;
          "
        ></iframe>
      </v-card>
    </v-dialog>

    <!-- Delete doc confirmation dialog -->
    <v-dialog
      v-model="deleteDocDialog"
      persistent
      max-width="290"
      v-if="selectedDoc"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this Document? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteFile(selectedDoc.uuid)"
            :loading="deleteDocLoading"
          >
            Delete Doc
          </v-btn>
          <v-btn color="primary" text @click="deleteDocDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Role Change dialog -->
    <v-dialog v-model="updateRoleDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Change App Role </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="roleForm">
            <v-text-field
              flat
              outlined
              readonly
              label="Current Role"
              :value="staff.roles ? staff.roles.name : null"
            ></v-text-field>
            <v-skeleton-loader
              type="image"
              height="50"
              v-if="dataLoading"
            ></v-skeleton-loader>
            <v-autocomplete
              auto-select-first
              v-else
              flat
              v-model="newRole"
              :rules="[rules.required, rules.sameValue]"
              :items="roles"
              item-text="name"
              item-value="uuid"
              label="New Role"
              outlined
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateRoleDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateRoleX"
            :loading="updateRoleLoading"
          >
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update supervisor dialog -->
    <v-dialog v-model="updateSupervisorDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5">
          Update Immediate Supervisor
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="supervisorForm">
            <v-text-field
              flat
              outlined
              readonly
              label="Current Immediate Supervisor"
              :value="
                staff.staff
                  ? staff.staff.first_name + ' ' + staff.staff.last_name
                  : null
              "
            ></v-text-field>
            <v-skeleton-loader
              type="image"
              height="50"
              v-if="dataLoading"
            ></v-skeleton-loader>
            <v-autocomplete
              auto-select-first
              v-else
              flat
              v-model="newSupervisor"
              :rules="[rules.required, rules.sameValue]"
              :items="supervisors"
              item-text="name"
              item-value="uuid"
              label="New Immediate Supervisor"
              outlined
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateSupervisorDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateSupervisorX"
            :loading="updateSupervisorLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update email dialog -->
    <v-dialog v-model="updateEmailDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Update Office Email </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="emailForm">
            <v-text-field
              flat
              outlined
              readonly
              label="Current Office Email"
              :value="staff.primary_email"
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="New Office Email"
              v-model="newEmail"
              :rules="[rules.required, rules.email, rules.sameValue]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateEmailDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateEmailX"
            :loading="updateEmailLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update designation dialog -->
    <v-dialog v-model="updateDesignationDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Update Designation </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="designationForm">
            <v-text-field
              flat
              outlined
              readonly
              label="Current Designation"
              :value="staff.designation"
            ></v-text-field>
            <v-text-field
              flat
              outlined
              label="New Designation"
              v-model="newDesignation"
              :rules="[rules.required, rules.sameValue, rules.alphabets]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="updateDesignationDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateDesignationX"
            :loading="updateDesignationLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Staff left dialog -->
    <v-dialog v-model="staffLeftDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Archive a Staff </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="staffLeftForm">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Left Date"
                  v-model="leftDate"
                  :rules="[rules.required]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="leftDate"
                color="primary"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="staffLeftDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="staffLeftX"
            :loading="staffLeftLoading"
          >
            Archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Staff rejoin dialog -->
    <v-dialog v-model="staffRejoinDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Staff Rejoin </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="staffRejoinForm">
            <v-text-field
              flat
              outlined
              label="Designation"
              v-model="rejoinDesignation"
              :rules="[rules.required, rules.alphabets]"
            ></v-text-field>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Rejoin Date"
                  v-model="rejoinDate"
                  :rules="[rules.required]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="rejoinDate"
                color="primary"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              flat
              outlined
              label="Age at Rejoining"
              v-model="rejoinAge"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="staffRejoinDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="staffRejoinX"
            :loading="staffRejoinLoading"
          >
            Rejoin
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Staff contract dialog -->
    <v-dialog v-model="staffContractDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Update Contract </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="staffContractForm">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu3"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Contract start date"
                  v-model="contractStartDate"
                  :rules="[rules.required]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="contractStartDate"
                color="primary"
                no-title
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Contract end date"
                  v-model="contractEndDate"
                  :rules="[rules.required]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="contractEndDate"
                color="primary"
                no-title
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="staffContractDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateContractX"
            :loading="staffContractLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Actiavate staff confirmation dialog -->
    <v-dialog v-model="activateStaffDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to activate this Staff?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="activateStaffDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="activateStaffX()"
            :loading="activateStaffLoading"
          >
            Activate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Deactiavate staff confirmation dialog -->
    <v-dialog v-model="deactivateStaffDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to deactivate this Staff?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="deactivateStaffDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deactivateStaffX()"
            :loading="deactivateStaffLoading"
          >
            Deactivate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- History dialog -->
    <v-dialog v-model="historyDialog" persistent max-width="480" scrollable>
      <v-card outlined>
        <v-card-title class="text-h5">
          <v-icon class="me-2">mdi-history</v-icon> History
          <v-spacer></v-spacer>
          <v-btn icon text @click="historyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item
              color="grey lighten-1"
              left
              small
              fill-dot
              v-for="(record, i) in history"
              :key="'h' + i"
            >
              <v-list-item class="pa-0" v-if="history_type == 'UP_JOIN'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.date_of_joining }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Join date </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.designation }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Designation </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.left_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Left date </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else-if="history_type == 'UP_CONTR'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.contract_start_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Contract start date
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.contract_end_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Contract end date
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else>
                <v-list-item-content class="pa-0">
                  <v-list-item-title v-if="history_type == 'UP_DESIG'">
                    {{ record.designation }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_EMAIL'">
                    {{ record.primary_email }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_IMSUP'">
                    {{ record.immediate_supervisor_name }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_ROLE'">
                    {{ record.role_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      i == 0
                        ? "Beginning"
                        : $_formatTimestamp(history[i - 1].created_at)
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-timeline-item>
            <v-timeline-item color="grey lighten-1" left small fill-dot>
              <v-list-item class="pa-0" v-if="history_type == 'UP_JOIN'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ staff.date_of_joining }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Join date </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ staff.designation }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Designation </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title v-if="staff.left_date">
                    {{ staff.left_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="staff.left_date">
                    Left date
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else-if="history_type == 'UP_CONTR'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ staff.contract_start_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Contract start date
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ staff.contract_end_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Contract end date
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else>
                <v-list-item-content class="pa-0">
                  <v-list-item-title v-if="history_type == 'UP_DESIG'">
                    {{ staff.designation }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_EMAIL'">
                    {{ staff.primary_email }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_IMSUP'">
                    {{ staff.staff.first_name + " " + staff.staff.last_name }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_ROLE'">
                    {{ staff.roles.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="history.length > 0">
                    {{
                      history.length > 0
                        ? $_formatTimestamp(
                            history[history.length - 1].created_at
                          )
                        : "Current"
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  

    
    <script>
import { mapState } from "vuex";
import compress from "compress-base64";
import { v4 as uuidv4 } from "uuid";
import {
  getStaff,
  deleteStaff,
  updateProfile,
  updateRole,
  updateSupervisor,
  updateEmail,
  updateDesignation,
  updateContract,
  staffLeft,
  staffRejoin,
  activateStaff,
  deactivateStaff,
  syncOfflineStaffChanges,
} from "@/services/staffService";
import {
  fetchStaffDocuments,
  addStaffDocument,
  deleteSaffDocument,
} from "@/services/staffDocumentsService";
import {
  fetchAndStoreStaffData,
  fetchAndStoreRoleSData,
} from "@/services/preloadDataService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    historyDialog: false,
    history: [],
    history_type: null,
    oldValue: null,
    rules: {},

    staff: {},
    loading: false,

    docViewDialog: false,
    selectedDoc: null,
    deleteDocLoading: false,
    deleteDocDialog: false,
    docAddLoading: false,
    docGetLoading: false,
    docUploadDialog: false,
    docUp: {
      uuid: "",
      name: "",
      description: "",
      file: null,
      binary_file: "",
    },
    documets: [],

    dataLoading: false,

    updateProfileLoading: false,
    newProfile: null,

    updateRoleDialog: false,
    updateRoleLoading: false,
    roles: [],
    newRole: null,

    updateSupervisorDialog: false,
    updateSupervisorLoading: false,
    supervisors: [],
    newSupervisor: null,

    updateEmailDialog: false,
    updateEmailLoading: false,
    newEmail: null,

    updateDesignationDialog: false,
    updateDesignationLoading: false,
    newDesignation: null,

    staffLeftDialog: false,
    staffLeftLoading: false,
    leftDate: null,

    staffRejoinDialog: false,
    staffRejoinLoading: false,
    rejoinDesignation: null,
    rejoinDate: null,
    rejoinAge: null,

    deleteStaffDialog: false,
    deleteStaffLoading: false,

    activateStaffDialog: false,
    activateStaffLoading: false,

    deactivateStaffDialog: false,
    deactivateStaffLoading: false,

    staffContractDialog: false,
    staffContractLoading: false,
    contractEndDate: null,
    contractStartDate: null,
  }),
  async created() {
    this.rules = {
      required: (value) => !!value || "Required.",
      email: (value) => {
        if (!value) return true;
        return /.+@.+\..+/.test(value) || "Invalid e-mail.";
      },
      alphabets: (value) => {
        if (!value) return true;
        return (
          /^[A-Za-z\s]+$/.test(value) ||
          "Invalid input; only alphabetic letters are allowed."
        );
      },
      fileSize: (value) => {
        if (!value) return true;
        return value.size <= 50 * 1024 || "File size must be less than 50 KB.";
      },
      sameValue: (value) => {
        return value != this.oldValue || "New value must be different.";
      },
    };

    if (this.$_checkPermission(this.auth.permissions, "Manage Staff", "Read")) {
      this.loading = true;
      await syncOfflineStaffChanges();
      this.docGetLoading = true;
      let getStaffReturn = await getStaff(this.$route.params.uuid);
      this.staff = getStaffReturn;
      this.loading = false;
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Staff Document",
          "Read"
        )
      ) {
        this.getFiles();
      } else {
        this.docGetLoading = false;
      }
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  updated() {
    this.rejoinAge = this.$_calculateAgeAtJoining(
      this.rejoinDate,
      this.staff.date_of_birth
    );
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageStaffList" });
    },
    editItem(item) {
      this.$router.push({
        name: "PageEditStaff",
        params: { uuid: item.uuid },
        query: { bc: item.first_name + " " + item.last_name },
      });
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    previewImage(event) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        let img = reader.result;
        let type = img.substring("data:image/".length, img.indexOf(";base64"));
        compress(reader.result, {
          width: 400,
          type: "image/" + type, // default
          max: 200, // max size
          min: 20, // min size
          quality: 0.8,
        })
          .then((result) => {
            this.newProfile = result;
          })
          .catch((error) => {
            console.error("Error during image compression:", error);
          });
      };
    },
    async reFetchStaff() {
      this.loading = true;
      await syncOfflineStaffChanges();
      let getStaffReturn = await getStaff(this.$route.params.uuid);
      this.staff = getStaffReturn;
      this.loading = false;
    },
    async uploadProfile() {
      this.updateProfileLoading = true;
      let updateProfileReturn = await updateProfile({
        uuid: this.staff.uuid,
        profile: this.newProfile,
      });
      this.updateProfileLoading = false;

      if (updateProfileReturn == "success") {
        this.$store.dispatch("updateAuthField", {
          field: "profile",
          value: this.newProfile,
        });
        this.reFetchStaff();
        this.newProfile = null;
      }
    },
    async updateRoleX() {
      if (this.$refs.roleForm.validate()) {
        this.updateRoleLoading = true;
        let updateRoleReturn = await updateRole({
          uuid: this.staff.uuid,
          role_uuid: this.newRole,
        });
        this.updateRoleLoading = false;

        if (updateRoleReturn == "success") {
          this.updateRoleDialog = false;
          this.$refs.roleForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async updateSupervisorX() {
      if (this.$refs.supervisorForm.validate()) {
        this.updateSupervisorLoading = true;
        let updateSupervisorReturn = await updateSupervisor({
          uuid: this.staff.uuid,
          immediate_supervisor_uuid: this.newSupervisor,
        });
        this.updateSupervisorLoading = false;

        if (updateSupervisorReturn == "success") {
          this.updateSupervisorDialog = false;

          this.$refs.supervisorForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async updateEmailX() {
      if (this.$refs.emailForm.validate()) {
        this.updateEmailLoading = true;
        let updateEmailReturn = await updateEmail({
          uuid: this.staff.uuid,
          email: this.newEmail,
        });
        this.updateEmailLoading = false;

        if (updateEmailReturn == "success") {
          this.updateEmailDialog = false;

          this.$refs.emailForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async updateDesignationX() {
      if (this.$refs.designationForm.validate()) {
        this.updateDesignationLoading = true;
        let updateDesignationReturn = await updateDesignation({
          uuid: this.staff.uuid,
          designation: this.newDesignation,
        });
        this.updateDesignationLoading = false;

        if (updateDesignationReturn == "success") {
          this.updateDesignationDialog = false;

          this.$refs.designationForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async updateContractX() {
      if (this.$refs.staffContractForm.validate()) {
        this.staffContractLoading = true;
        let updateContractReturn = await updateContract({
          uuid: this.staff.uuid,
          contract_start_date: this.contractStartDate,
          contract_end_date: this.contractEndDate,
        });
        this.staffContractLoading = false;

        if (updateContractReturn == "success") {
          this.staffContractDialog = false;

          this.$refs.staffContractForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async staffLeftX() {
      if (this.$refs.staffLeftForm.validate()) {
        this.staffLeftLoading = true;
        let staffLeftReturn = await staffLeft({
          uuid: this.staff.uuid,
          left_date: this.leftDate,
        });
        this.staffLeftLoading = false;

        if (staffLeftReturn == "success") {
          this.staffLeftDialog = false;

          this.$refs.staffLeftForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async staffRejoinX() {
      if (this.$refs.staffRejoinForm.validate()) {
        this.staffRejoinLoading = true;
        let staffRejoinReturn = await staffRejoin({
          uuid: this.staff.uuid,
          designation: this.rejoinDesignation,
          date_of_joining: this.rejoinDate,
          age_at_joining: this.rejoinAge,
        });
        this.staffRejoinLoading = false;

        if (staffRejoinReturn == "success") {
          this.staffRejoinDialog = false;

          this.$refs.staffRejoinForm.reset();
          this.reFetchStaff();
        }
      }
    },
    async activateStaffX() {
      this.activateStaffLoading = true;
      let activateStaffReturn = await activateStaff({
        uuid: this.staff.uuid,
      });
      this.activateStaffLoading = false;
      if (activateStaffReturn == "success") {
        this.activateStaffDialog = false;

        this.reFetchStaff();
      }
    },
    async deactivateStaffX() {
      this.deactivateStaffLoading = true;
      let deactivateStaffReturn = await deactivateStaff({
        uuid: this.staff.uuid,
      });
      this.deactivateStaffLoading = false;
      if (deactivateStaffReturn == "success") {
        this.deactivateStaffDialog = false;

        this.reFetchStaff();
      }
    },
    async deleteStaffX() {
      this.deleteStaffLoading = true;
      let deleteStaffReturn = await deleteStaff({
        uuid: this.staff.uuid,
      });
      this.deleteStaffLoading = false;
      if (deleteStaffReturn == "success") {
        this.deleteStaffDialog = false;
        this.goBack();
      }
    },
    async getFiles() {
      this.docGetLoading = true;
      this.documets = await fetchStaffDocuments(this.$route.params.uuid);
      this.docGetLoading = false;
    },
    async uploadFile() {
      if (this.$refs.docForm.validate()) {
        let reader = new FileReader();
        reader.readAsDataURL(this.docUp.file);
        reader.onload = async () => {
          this.docUp.binary_file = reader.result;
          this.docUp.uuid = uuidv4();
          this.docAddLoading = true;
          let addStaffDocumentReturn = await addStaffDocument(
            this.staff.uuid,
            this.docUp
          );
          this.docAddLoading = false;
          if (addStaffDocumentReturn == "success") {
            this.docUploadDialog = false;
            (this.docUp = {
              uuid: "",
              name: "",
              description: "",
              file: null,
              binary_file: "",
            }),
              this.getFiles();
          }
        };
      }
    },
    async deleteFile(uuid) {
      this.deleteDocLoading = true;
      let deleteStaffDocumentReturn = await deleteSaffDocument(
        this.staff.uuid,
        {
          uuid: uuid,
        }
      );
      this.deleteDocLoading = false;
      if (deleteStaffDocumentReturn == "success") {
        this.deleteDocDialog = false;
        this.getFiles();
      }
    },
    downloadBase64File(base64Data, fileName) {
      function extractMimeType(dataUrl) {
        const match = dataUrl.match(/^data:(.*?);base64,/);
        return match ? match[1] : "application/octet-stream";
      }

      let mimeType = "application/octet-stream";
      if (base64Data.startsWith("data:")) {
        mimeType = extractMimeType(base64Data);
      } else {
        const extension = fileName.split(".").pop().toLowerCase();
        const mimeTypes = {
          pdf: "application/pdf",
          txt: "text/plain",
          png: "image/png",
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          gif: "image/gif",
          svg: "image/svg+xml",
        };
        mimeType = mimeTypes[extension] || "application/octet-stream";
        base64Data = `data:${mimeType};base64,${base64Data}`;
      }

      const downloadLink = document.createElement("a");
      downloadLink.href = base64Data;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    async openRoleChange() {
      this.oldValue = this.staff.role_uuid;
      this.updateRoleDialog = true;
      this.dataLoading = true;
      this.roles = await fetchAndStoreRoleSData();
      this.dataLoading = false;
    },
    async openSupervisorChange() {
      this.oldValue = this.staff.immediate_supervisor_uuid;
      this.updateSupervisorDialog = true;
      this.dataLoading = true;
      this.supervisors = await fetchAndStoreStaffData();
      this.supervisors.forEach((supervisor) => {
        supervisor["name"] = supervisor.first_name + " " + supervisor.last_name;
      });
      this.dataLoading = false;
    },
    copyToClipboard(text) {
      this.$store.commit("setError", {
        isError: false,
        error: "Copied to clipboard!",
      });
      navigator.clipboard.writeText(text);
    },
  },
};
</script>