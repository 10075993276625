<template>
  <v-container fluid>
    <v-card
      flat
      outlined
      class="pa-sm-4 wraperx imso-toolbar"
      v-if="dataLoading"
    >
      <v-skeleton-loader type="image"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx imso-toolbar" v-else>
      <v-card-title class="primary--text">
        Mileage per Project - Private Vehicles Report
        <v-spacer></v-spacer>
      </v-card-title>
      <v-form ref="form">
        <v-card-title class="primary--text align-start">
          <v-col style="min-width: 150px">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  filled
                  :rules="[rules.required]"
                  flat
                  outlined
                  label="From (Date)"
                  v-model="startDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                color="primary"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col style="min-width: 150px">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu2"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[rules.required]"
                  filled
                  flat
                  outlined
                  label="To (Date)"
                  v-model="endDate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                color="primary"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col style="min-width: 200px">
            <v-autocomplete
              auto-select-first
              filled
              :rules="[rules.required]"
              flat
              v-model="projectUuid"
              :items="projects"
              item-text="code"
              item-value="uuid"
              label="Project"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
          <v-col style="min-width: 200px">
            <v-autocomplete
              auto-select-first
              filled
              flat
              v-model="vehicleUuids"
              :items="vehicles"
              item-text="text"
              item-value="uuid"
              label="Vehicle"
              outlined
              dense
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="me-1">{{ item.text }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ vehicleUuids.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col style="min-width: 200px">
            <v-autocomplete
              auto-select-first
              filled
              flat
              v-model="staffUuids"
              :items="staff"
              item-text="name"
              item-value="uuid"
              label="Staff"
              multiple
              outlined
              dense
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ staffUuids.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col style="min-width: 150px">
            <v-text-field
              filled
              :rules="[rules.required]"
              flat
              type="number"
              v-model="costPerKm"
              label="Cost per km"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              block
              height="40"
              depressed
              color="primary"
              :loading="loading"
              @click="fetchLogs()"
            >
              Generate Report
            </v-btn>
          </v-col>
        </v-card-title>
      </v-form>
    </v-card>
    <v-card
      flat
      outlined
      class="pa-sm-4 mt-6 wraperx dsd"
      id="print-only"
      v-if="records.length > 0"
    >
      <v-card-title>
        <v-sheet color="transparent">
          <v-sheet color="transparent"> Mileage per Project Report </v-sheet>
          <v-sheet color="transparent text-caption">
            Project Code : {{ project.code }}
          </v-sheet>
          <!-- <v-sheet color="transparent text-caption">
            Vehicle Class : {{ vehicleClass }}
          </v-sheet> -->
          <v-sheet color="transparent text-caption">
            Date : {{ startDate + " to " + endDate }}
          </v-sheet>
        </v-sheet>
        <v-spacer> </v-spacer>
        <!-- <v-sheet max-width="300"> -->
        <v-img
          max-width="250"
          contain
          alt="IMSO"
          :src="require('@/assets/logo.png')"
          class="ma-auto"
        />
        <!-- </v-sheet> -->
      </v-card-title>

      <v-card-text class="tableText--text">
        <table class="imso-table" id="imso-table-1" v-if="records.length > 0">
          <tr>
            <th rowspan="2">S.No</th>
            <th rowspan="2">Vehicle No</th>
            <th rowspan="2">Start km</th>
            <th rowspan="2">End km</th>
            <th rowspan="2">Total km</th>
            <th rowspan="1" :colspan="reportLedgers.length" v-if="reportLedgers.length > 0">
              {{ project.code }}
            </th>
            <th rowspan="2">{{ project.code }}</th>
            <th rowspan="2">Others</th>
            <th rowspan="2">Staff</th>
          </tr>
          <tr>
            <th v-for="ledger in reportLedgers" :key="ledger.uuid">
              {{ ledger.code }}
            </th>
          </tr>
          <tr v-for="(record, i) in records" :key="i">
            <td>
              {{ i + 1 }}
            </td>
            <td>
              {{ record.vehicle_no }}
            </td>
            <td class="number-td">
              {{ record.start_km }}
            </td>
            <td class="number-td">
              {{ record.end_km }}
            </td>
            <td class="number-td">
              {{ record.total_km }}
            </td>
            <td v-for="ledger in reportLedgers" :key="ledger.uuid" class="number-td">
              {{
                record.ledgers.find((obj) => obj.ledger_code === ledger.code)
                  ? record.ledgers.find(
                      (obj) => obj.ledger_code === ledger.code
                    ).ledger_total_km
                  : 0
              }}
            </td>
            <td class="number-td">
              {{ record.project_total_km }}
            </td>
            <td class="number-td">
              {{ record.others_km }}
            </td>
            <td>
              {{ record.staff_name }}
            </td>
          </tr>
          <tr>
            <th colspan="4">Total</th>
            <th class="number-td">{{ totalKm }}</th>
            <th
              v-for="ledger in reportLedgers"
              :key="ledger.uuid"
              class="number-td total-td"
            >
              {{
                ledgerRecords.find((obj) => obj.ledger_code === ledger.code)
                  ? ledgerRecords.find((obj) => obj.ledger_code === ledger.code)
                      .total_km
                  : 0
              }}
            </th>
            <th class="number-td total-td">{{ totaProjectKm }}</th>
            <th class="number-td total-td">{{ totalOthersKm }}</th>
            <th></th>
          </tr>
          <tr>
            <th colspan="5">Running Cost ({{ costPerKm }} LKR per km)</th>

            <th
              v-for="ledger in reportLedgers"
              :key="ledger.uuid"
              class="number-td total-td"
            >
              {{
                ledgerRecords.find((obj) => obj.ledger_code === ledger.code)
                  ? ledgerRecords.find((obj) => obj.ledger_code === ledger.code)
                      .total_km * costPerKm
                  : 0
              }}
            </th>
            <th class="number-td full-total-td">
              {{ totaProjectKm * costPerKm }}
            </th>
            <th class="total-td"></th>
            <th></th>
          </tr>
        </table>
        <v-sheet
          color="transparent"
          outlined
          class="mt-12 pt-12 text-center text-caption ml-auto"
          max-width="300"
        >
          <v-divider></v-divider>
          Approved By
        </v-sheet>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="text-caption text-right text--secondary pb-0 my-0">
        <v-spacer></v-spacer>
        This is a system generated report. Generated at :
        {{ new Date() }}. imso.openesrilanka.org
      </v-card-actions>
    </v-card>
    <v-card
      flat
      outlined
      class="pa-sm-4 mt-6 wraperx imso-toolbar d-flex justify-end align-center"
      v-if="records.length > 0"
    >
      <v-sheet color="transparent text-caption text--secondary">
        Please select the light theme before printing.
      </v-sheet>

      <v-btn color="primary" dark depressed @click="downloadPdf" class="ms-4"
        >Print</v-btn
      >
      <v-btn color="success" depressed @click="downloadTableAsCSV" class="ms-4"
        >Download CSV</v-btn
      >
      <v-btn color="lime" depressed @click="exportPDF" class="ms-4"
        >Download PDF Testing</v-btn
      >
    </v-card>
    <v-card
      flat
      outlined
      class="pa-sm-4 mt-6 wraperx imso-toolbar d-flex justify-center align-center"
      v-else-if="project"
    >
      No data found for the selected filters.
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAllVehicleRunning } from "@/services/logsService";
import {
  fetchAndStoreStaffData,
  fetchAndStoreVehiclesData,
  fetchAndStoreProjectsData,
} from "@/services/preloadDataService";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    menu1: false,
    menu2: false,
    dataLoading: false,
    loading: false,
    costPerKm: 0,
    items: [],
    page: 1,
    limit: -1,
    startDate: "",
    endDate: "",
    projectUuid: null,
    vehicleUuids: [],
    staffUuids: [],
    project: null,
    projects: [],
    vehicles: [],
    staff: [],
    ledgers: [],
    records: [],
    ledgerRecords: [],
    totalKm: 0,
    totaProjectKm: 0,
    totalOthersKm: 0,
    reportLedgers: [],
  }),
  async created() {
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle Running Logs",
        "Read All"
      ) &&
      this.$_checkPermission(this.auth.permissions, "Reports", "Generate")
    ) {
      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      this.vehicles.forEach((element) => {
        if (element.category)
          element.text = `${element.vehicle_no} [${element.category}]`;
        else element.text = element.vehicle_no;
      });
      this.projects = await fetchAndStoreProjectsData();
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((person) => {
        person["name"] = person.first_name + " " + person.last_name;
      });
      this.vehicles = this.vehicles.filter((obj) => obj.category == "Private");
      let indexPVT = this.projects.findIndex(
        (obj) => obj.code === "PRIVATE USE"
      );
      if (indexPVT !== -1) {
        this.projects.splice(indexPVT, 1);
      }

      this.endDate = this.formatDate(new Date());
      this.startDate = this.formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      );
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    async fetchLogs() {
      if (this.$refs.form.validate()) {
        this.records = [];
        this.ledgerRecords = [];
        this.totalKm = [];
        this.totaProjectKm = [];
        this.totalOthersKm = [];
        this.items = [];
        if (this.vehicleUuids.length == 0) {
          this.vehicles.forEach((element) => {
            this.vehicleUuids.push(element.uuid);
          });
        }
        this.loading = true;
        this.items = await fetchAllVehicleRunning(
          this.page,
          this.limit,
          this.startDate ? this.startDate : "",
          this.endDate ? this.endDate : "",
          JSON.stringify(this.vehicleUuids),
          JSON.stringify(this.staffUuids),
          JSON.stringify([this.projectUuid]),
          JSON.stringify(["APPROVED"]),
          JSON.stringify(["REQUEST_REJECTED", "DISABLED"])
        );

        this.items = this.items.data;

        this.changeProjcet();

        [
          this.records,
          this.ledgerRecords,
          this.totalKm,
          this.totaProjectKm,
          this.totalOthersKm,
        ] = await this.generateReport(this.items);

        this.reportLedgers = this.ledgers.filter((ledger) => {
            return (
              this.ledgerRecords.find(
                (obj) => obj.ledger_code === ledger.code
              ) ?? false
            );
          });

        this.loading = false;
      }
    },
    async generateReport(items) {
      const itemsGrpByVeicle = this.$_.groupBy(items, "vehicle_uuid");
      let records = [];
      let ledgerRecords = [];

      let itemsLedgerRecord = [];
      this.$_.forEach(itemsGrpByVeicle, (itemGrpByVeicle) => {
        const start_km = itemGrpByVeicle[0].vehicle_km_data.start_km;
        const end_km = itemGrpByVeicle[0].vehicle_km_data.end_km;

        const itemsGrpByDriver = this.$_.groupBy(
          itemGrpByVeicle,
          "driver_uuid"
        );
        this.$_.forEach(itemsGrpByDriver, (itemGrpByDriver) => {
          const itemsGrpByLedger = this.$_.groupBy(
            itemGrpByDriver,
            "project_ledger_uuid"
          );
          let ledgers = [];
          this.$_.forEach(itemsGrpByLedger, (itemGrpByLedger) => {
            if (itemGrpByLedger[0].project_ledger_uuid) {
              ledgers.push({
                ledger_code: itemGrpByLedger[0].project_ledgers.code,
                ledger_total_km: this.$_.sumBy(itemGrpByLedger, "total_km"),
              });
              itemsLedgerRecord.push({
                ledger_code: itemGrpByLedger[0].project_ledgers.code,
                ledger_total_km: this.$_.sumBy(itemGrpByLedger, "total_km"),
              });
            } else {
              ledgers.push({
                ledger_code: "OTHERS",
                ledger_total_km: this.$_.sumBy(itemGrpByLedger, "total_km"),
              });
              itemsLedgerRecord.push({
                ledger_code: "OTHERS",
                ledger_total_km: this.$_.sumBy(itemGrpByLedger, "total_km"),
              });
            }
          });
          records.push({
            vehicle_no: itemGrpByDriver[0].vehicles.vehicle_no,
            start_km: start_km,
            end_km: end_km,
            total_km: end_km - start_km,
            project_total_km: this.$_.sumBy(itemGrpByDriver, "total_km"),
            others_km:
              end_km - start_km - this.$_.sumBy(itemGrpByDriver, "total_km"),
            ledgers: ledgers,
            staff_name:
              itemGrpByDriver[0].staff.first_name +
              " " +
              itemGrpByDriver[0].staff.last_name,
          });
        });
      });

      const itemsLedgerRecordGrpByCode = this.$_.groupBy(
        itemsLedgerRecord,
        "ledger_code"
      );
      this.$_.forEach(itemsLedgerRecordGrpByCode, (qvalue, key) => {
        ledgerRecords.push({
          ledger_code: key,
          total_km: this.$_.sumBy(qvalue, "ledger_total_km"),
        });
      });

      const totalKm = this.$_.sumBy(records, "total_km");
      const totaProjectKm = this.$_.sumBy(records, "project_total_km");
      const totalOthersKm = this.$_.sumBy(records, "others_km");

      return [records, ledgerRecords, totalKm, totaProjectKm, totalOthersKm];
    },
    changeProjcet() {
      this.project = this.projects.find((obj) => obj.uuid === this.projectUuid);
      this.ledgers = this.project.project_ledgers;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, add 1 and pad with 0
      const day = String(date.getDate()).padStart(2, "0"); // Pad day with 0 if necessary
      return `${year}-${month}-${day}`;
    },
    downloadPdf() {
      window.print();
    },
    downloadTableAsCSV() {
      const tableIds = ["imso-table-1"];
      const filename =
        "Mileage per Project - Private Vehicles Report - " + this.project.code;

      const csv = [];
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");

          // Track the grid position for rowspan and colspan cells
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          for (rowIndex; rowIndex < rows.length; rowIndex++) {
            const row = rows[rowIndex];
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            for (let cell of cells) {
              // Skip over grid positions that are already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              // Get cell content and replace commas to avoid CSV issues
              let cellContent = cell.textContent.replace(/[,|\n]/g, " ");

              // Add cell content to rowData
              rowData[columnIndex] = cellContent;

              // Handle colspan
              let colspan = cell.getAttribute("colspan") || 1;
              colspan = parseInt(colspan);

              // Handle rowspan
              let rowspan = cell.getAttribute("rowspan") || 1;
              rowspan = parseInt(rowspan);

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                if (!cellGrid[rowIndex + r]) {
                  cellGrid[rowIndex + r] = [];
                }
                for (let c = 0; c < colspan; c++) {
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            }

            // Add row data to CSV, ensuring commas separate the columns
            csv.push(rowData.join(","));
          }
        }
      });

      // Join all rows with a newline character to form the CSV string
      const csvContent = csv.join("\n");

      // Create a temporary download link and trigger the download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link after download
      document.body.removeChild(link);
    },
    exportPDF() {
      const tableIds = ["imso-table-1"];
      const filename =
        "Mileage per Project - Private Vehicles Report - " + this.project.code;

      // Create a new jsPDF instance
      const doc = new jsPDF({ orientation: "landscape" });

      // Add header text
      const headerText = filename;
      doc.setFontSize(16);
      doc.text(headerText, 14, 14); // Position the header at the top-left of the page

      doc.setFontSize(12);
      doc.text(this.startDate + " to " + this.endDate, 14, 20); 

      // Add an image to the top-right corner
      const imgData = require("@/assets/logo.png"); // Adjust the path to your image file
      const imgWidth = 55; // Image width
      const imgHeight = 20; // Image height
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the width of the page
      doc.addImage(
        imgData,
        "PNG",
        pageWidth - imgWidth - 10,
        5,
        imgWidth,
        imgHeight
      ); // Position image

      // Starting position for tables
      let currentY = 25; // Start position below the header and image

      // Process each table
      tableIds.forEach((tableId) => {
        const table = document.getElementById(tableId);
        if (table) {
          const rows = table.querySelectorAll("tr");
          const tableData = [];

          // Track grid for rowspan/colspan
          const cellGrid = [];
          let rowIndex = 0;

          // Loop through each row of the table
          rows.forEach((row) => {
            const cells = row.querySelectorAll("th, td");
            const rowData = [];
            let columnIndex = 0;

            // Ensure grid is initialized for this row
            if (!cellGrid[rowIndex]) {
              cellGrid[rowIndex] = [];
            }

            // Process each cell in the current row
            cells.forEach((cell) => {
              // Skip over grid positions already occupied by a rowspan/colspan
              while (cellGrid[rowIndex][columnIndex]) {
                columnIndex++;
              }

              let cellContent = cell.textContent.replace(/[,|\n]/g, " "); // Clean cell content

              // Handle colspan and rowspan
              const colspan = parseInt(cell.getAttribute("colspan") || 1);
              const rowspan = parseInt(cell.getAttribute("rowspan") || 1);

              // Fill the rowData with the cell content
              rowData[columnIndex] = cellContent;

              // Mark the grid for this cell's colspan and rowspan
              for (let r = 0; r < rowspan; r++) {
                for (let c = 0; c < colspan; c++) {
                  if (!cellGrid[rowIndex + r]) {
                    cellGrid[rowIndex + r] = [];
                  }
                  cellGrid[rowIndex + r][columnIndex + c] = true;
                }
              }

              // Move the column index forward by the colspan amount
              columnIndex += colspan;
            });

            tableData.push(rowData);
            rowIndex++;
          });

          // Add header and body rows to the PDF
          doc.autoTable({
            head: [tableData[0]], // First row as the header
            body: tableData.slice(1), // All remaining rows as the body
            startY: currentY, // Start position for the table
            theme: "grid", // Grid theme
            headStyles: {
              fillColor: [8, 87, 110], // Blue background for header
              textColor: [255, 255, 255], // White text color
            },
            columnStyles: {
              // Optional: You can set column widths here if needed
              // 0: { cellWidth: 40 },
              // 1: { cellWidth: 100 },
            },
            margin: { top: 10, bottom: 10 },
            tableWidth: "auto", // Table width auto to fit page
          });

          // Update the Y position for the next table
          currentY = doc.lastAutoTable.finalY + 10; // 10 units spacing between tables
        }
      });

      // Add footer content
      const footerText = `
-------------------------------------------------
            Approved By                  

This is a system-generated report. Generated at: ${new Date().toLocaleString()}. imso.openesrilanka.org`;

      const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of the page
      const footerLines = footerText.trim().split("\n");
      const fontSize = 10;
      doc.setFontSize(fontSize);

      // Align each line to the right
      footerLines.forEach((line, index) => {
        const textWidth = doc.getTextWidth(line);
        const xPosition = pageWidth - textWidth - 10; // 10 units margin from the right
        const yPosition = pageHeight - 25 + index * (fontSize - 5); // 40 units from the bottom, line spacing adjusted
        doc.text(line, xPosition, yPosition);
      });

      // Save the generated PDF
      doc.save(filename);
    },
  },
};
</script>

<style>
.dsd {
  overflow-x: scroll !important;
}

.mkm {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>