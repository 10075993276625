import Vue from "vue";

Vue.mixin({
  methods: {
    $_checkPermission(permissions, module, action) {
      return permissions.some(
        (permission) =>
          permission.permissions.models.name == module &&
          permission.permissions.name == action
      );
    },

    $_getHistory(histories, type, type2 = null) {
      return histories
        ? histories.filter(
            (history) =>
              history.type == type || (type2 && history.type == type2)
          )
        : [];
    },

    $_formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${year}-${month}-${day}`;
    },

    $_formatTimestampX(timestamp) {
      const date = new Date(timestamp);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    $_calculateAgeAtJoining(joiningDate, birthDate) {
      if (!joiningDate || !birthDate) return null;
      const ageInMilliseconds = new Date(joiningDate) - new Date(birthDate);
      return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365);
    },

    $_daysDifference(inputDate) {
      const input = new Date(inputDate);
      const current = new Date();
      const differenceInTime = input.getTime() - current.getTime();
      const differenceInDays = Math.round(
        differenceInTime / (1000 * 3600 * 24)
      );
      return differenceInDays;
    },

    $_calculateMeterDiffrence(currentMeter, previousMeter) {
      return currentMeter - previousMeter;
    },

    $_calculateDays(fromDate, toDate) {
      if (fromDate && toDate) {
        const start = new Date(fromDate);
        const end = new Date(toDate);
        const diffTime = end - start;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        return diffDays;
      } else {
        return 0;
      }
    },

    $_getFirstDateOfYear() {
      const currentYear = new Date().getFullYear();
      const firstDateOfYear = new Date(currentYear, 0, 1);
      return firstDateOfYear.toISOString().split("T")[0];
    },

    $_calculateMonthsWorked(joinDate, currentDate = new Date()) {
      const startDate = new Date(joinDate);

      const startYear = startDate.getFullYear();
      const startMonth = startDate.getMonth();

      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();

      const yearDifference = currentYear - startYear;
      const monthDifference = currentMonth - startMonth;

      const totalMonthsWorked = yearDifference * 12 + monthDifference;

      return totalMonthsWorked > 0 ? totalMonthsWorked : 0;
    },

    $_calculateContractMonths(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
    
      const startYear = start.getFullYear();
      const startMonth = start.getMonth();
    
      const endYear = end.getFullYear();
      const endMonth = end.getMonth();
    
      const yearDifference = endYear - startYear;
      const monthDifference = endMonth - startMonth;
    
      const totalMonths = yearDifference * 12 + monthDifference + 1;
    
      return totalMonths > 0 ? totalMonths : 0;
    },

    $_getLastDateOfAYear(year) {
      const lastDate = new Date(year, 11, 31);
      return lastDate.toISOString().split("T")[0];
    },
    $_getFirstDateOfAYear(year) {
      const firstDate = new Date(year, 0, 1);
      return firstDate.toISOString().split("T")[0];
    },

    $_getSubordinates(staffArray, supervisorUuid) {
      return staffArray
        .filter((staff) => staff.immediate_supervisor_uuid === supervisorUuid)
        .map((subordinate) => subordinate.uuid);
    },

    $_isSubordinate(staffArray, supervisorUuid, subordinateUuid) {
      const subordinate = staffArray.find(
        (staff) => staff.uuid === subordinateUuid
      );
      return subordinate
        ? subordinate.immediate_supervisor_uuid === supervisorUuid
        : false;
    },
    $_getMonthFromDate(dateString) {
      const date = new Date(dateString);
      const month = date.getMonth() + 1;
      return month;
  }
  },
});
