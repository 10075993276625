<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="dataLoading">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>
    </v-card>
    <v-stepper v-model="step" v-else class="wraperx" flat outlined>
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
          editable
          :rules="[() => step == 1 || $refs.dispatchForm.validate()]"
        >
          Dispatch Information
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2" editable>
          Checklist
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" editable> Return Information </v-stepper-step>
      </v-stepper-header>
      <v-divider></v-divider>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-3 wraperx" flat>
            Dispatch Information
            <v-form ref="dispatchForm">
              <v-row class="mt-8">
                <v-col cols="12" md="6" lg="6" class="py-0" v-if="is_overrule">
                  <v-autocomplete
                    auto-select-first
                    flat
                    v-model="log.driver_uuid"
                    :items="drivers"
                    item-text="name"
                    item-value="uuid"
                    label="Driver *"
                    outlined
                    :rules="[rules.VeryRequired]"
                    @change="changeDriver"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    auto-select-first
                    flat
                    v-model="log.vehicle_uuid"
                    :rules="[rules.VeryRequired]"
                    :items="vehicles"
                    item-text="text"
                    item-value="uuid"
                    label="Vehicle No *"
                    outlined
                    @change="changeVehicle"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="menu1"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        outlined
                        label="Date *"
                        v-model="log.date"
                        :rules="[
                          rules.VeryRequired,
                          rules.todayOrYesterday,
                          rules.todayOrPastDate,
                        ]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="log.date"
                      color="primary"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="menu2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        outlined
                        label="Dispatch Time *"
                        v-model="log.dispatch_time"
                        :rules="[rules.required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="log.dispatch_time"
                      color="primary"
                      @input="menu2 = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    type="number"
                    flat
                    outlined
                    label="Dispatch Meter (km) *"
                    v-model="log.dispatch_km"
                    :rules="[
                      rules.required,
                      rules.dispatcMeterReadingOPEnE,
                      rules.dispatcMeterReading,
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0" v-if="is_overrule">
                  <v-checkbox v-model="editable" label="Editable"></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-divider></v-divider>
          <v-sheet color="transparent" class="d-flex justify-end mt-4">
            <v-btn text @click="goBack"> Cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="addVehicleRunningX"
              :loading="loading"
              v-if="editable"
            >
              Submit
            </v-btn>
            <v-btn v-else color="primary" @click="step = 2"> Continue </v-btn>
          </v-sheet>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-3 wraperx" flat>
            Checklist

            <v-row class="mt-5 px-2">
              <v-col
                cols="6"
                class="pt-0"
                v-for="(item, i) in checklist"
                :key="i + 'c'"
              >
                <v-checkbox
                  :label="item"
                  :value="item"
                  v-model="log.check_list"
                  color="primary"
                  hide-details
                  dense
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" class="py-0 mt-5">
                <v-textarea
                  flat
                  outlined
                  label="Defects"
                  v-model="log.defects"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>
          <v-divider></v-divider>
          <v-sheet color="transparent" class="d-flex justify-end mt-4">
            <v-btn text @click="step = 1"> Back </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="saveLog" class="me-2">
              Save
            </v-btn>

            <v-btn color="primary" @click="step = 3"> Continue </v-btn>
          </v-sheet>
          <v-sheet
            color="transparent"
            class="d-flex justify-end text-caption mt-2"
            v-if="log.saved_at"
          >
            Saved at {{ new Date(log.saved_at) }}
          </v-sheet>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-3 wraperx" flat>
            Return Information
            <v-form ref="returnForm">
              <v-row class="mt-8">
                <v-col cols="12" class="py-0">
                  <v-file-input
                    v-model="image"
                    :rules="[rules.required]"
                    label="Image *"
                    outlined
                    accept="image/*"
                    @change="previewImage"
                  ></v-file-input>
                  <v-img
                    v-if="log.image_binary_file"
                    :src="log.image_binary_file"
                    max-height="400"
                    max-width="400"
                    width="100%"
                    contain
                    class="mx-auto mb-8"
                  />
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    type="number"
                    flat
                    outlined
                    label="Return Meter (km) *"
                    v-model="log.return_km"
                    :rules="[rules.required, rules.returnMeterReading]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-text-field
                    flat
                    outlined
                    label="Total km"
                    v-model="log.total_km"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                    v-model="menu3"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        flat
                        outlined
                        label="Return Time *"
                        v-model="log.return_time"
                        :rules="[rules.required]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="log.return_time"
                      color="primary"
                      @input="menu3 = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    auto-select-first
                    flat
                    v-model="log.project_uuid"
                    :rules="[rules.required]"
                    :items="projects"
                    item-text="code"
                    item-value="uuid"
                    label="Project *"
                    outlined
                    @change="changeProjcet"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6" class="py-0">
                  <v-autocomplete
                    auto-select-first
                    flat
                    v-model="log.project_ledger_uuid"
                    :rules="[rules.ledgersR]"
                    :items="ledgers"
                    item-text="code"
                    item-value="uuid"
                    label="Ledger"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    flat
                    outlined
                    :rules="[rules.required]"
                    label="Description *"
                    v-model="log.description"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-divider></v-divider>
          <v-sheet color="transparent" class="d-flex justify-end mt-4">
            <v-btn text @click="step = 2"> Back </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="addVehicleRunningX"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-sheet>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { addVehicleRunning } from "@/services/vehicleRunningService";
import {
  fetchAndStoreVehiclesData,
  fetchAndStoreProjectsData,
  fetchAndStoreStaffData,
} from "@/services/preloadDataService";

import constants from "@/constants.json";
import compress from "compress-base64";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    runningLog: (state) => state.runningLog.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    step: 1,
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    rules: {},
    loading: false,
    dataLoading: false,

    vehicles: [],
    projects: [],
    drivers: [],
    ledgers: [],
    checklist: constants.vehicleChecklist,
    log: {
      uuid: "",
      driver_uuid: "",
      vehicle_uuid: "",
      project_uuid: "",
      project_ledger_uuid: "",
      date: "",
      dispatch_time: "",
      return_time: "",
      dispatch_km: null,
      return_km: null,
      total_km: null,
      image_binary_file: "",
      image_link: "",
      image_meta: "",
      check_list: [],
      defects: "",
      description: "",
      edit_status: "DISABLED",
      staff: {},
      projects: {},
      project_ledgers: {},
      vehicles: {},
      saved_at: "",
      review_status: "PENDING",
    },
    editable: false,
    image: null,
    current_meter_reading: null,
    is_overrule: false,
    selectedVehicle: {},
    isPreviewed: false,
  }),
  async created() {
    this.rules = {
      VeryRequired: (value) => !!value || "Required.",
      required: (value) => !!value || this.editable || "Required.",
      ledgersR: (value) => !!value || this.ledgers.length == 0 || "Required.",
      dispatcMeterReading: (value) => {
        if (!value) return true;
        return (
          Number(value) >= Number(this.current_meter_reading) ||
          this.is_overrule ||
          `Meter Reading should be greater than or equal to the current value (${this.current_meter_reading}).`
        );
      },
      dispatcMeterReadingOPEnE: (value) => {
        if (!value) return true;
        return (
          (this.selectedVehicle &&
            this.selectedVehicle.category == "OPEnE" &&
            Number(value) == Number(this.current_meter_reading)) ||
          (this.selectedVehicle && this.selectedVehicle.category != "OPEnE") ||
          this.is_overrule ||
          Number(this.current_meter_reading == 0) ||
          `Meter Reading should be equal to the current value (${this.current_meter_reading}). Because it's a OPEnE Vehicle.`
        );
      },
      returnMeterReading: (value) => {
        if (!value) return true;
        return (
          Number(value) > Number(this.log.dispatch_km) ||
          `Meter Reading should be greater than the dispatch reading (${this.log.dispatch_km}).`
        );
      },
      pastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate < today || "The date must be in the past.";
      },
      todayOrFutureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate >= today || "The date must be today or in the future.";
      },
      futureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate > today || "The date must be in the future.";
      },
      todayOrPastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate <= today || "The date must be today or in the past.";
      },
      todayOrYesterday: (value) => {
        if (!value) return true;
        if (this.is_overrule) {
          return true;
        }
        
        const inputDate = new Date(value);
        const now = new Date();
        const today = new Date();
        const yesterday = new Date();

        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        if (
          inputDate.getTime() === today.getTime() ||
          inputDate.getTime() === yesterday.getTime()
        ) {
          if (inputDate.getTime() === yesterday.getTime()) {
            const tenAMToday = new Date();
            tenAMToday.setHours(10, 0, 0, 0);

            if (now.getTime() < tenAMToday.getTime()) {
              return true;
            } else {
              return "You can only log for yesterday before 10 AM today.";
            }
          }
          return true;
        }

        return "The date must be today or yesterday.";
      },
    };

    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Vehicle Running Logs",
        "Create"
      )
    ) {
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Vehicle Running Logs",
          "Overrule"
        )
      ) {
        this.is_overrule =
          this.$route.query.overrule && this.$route.query.overrule == "true";
      }

      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      this.vehicles.forEach((element) => {
        if (element.category)
          element.text = `${element.vehicle_no} [${element.category}]`;
        else element.text = element.vehicle_no;
      });
      this.projects = await fetchAndStoreProjectsData();
      this.drivers = await fetchAndStoreStaffData();
      this.drivers.forEach((driver) => {
        driver["name"] = driver.first_name + " " + driver.last_name;
      });

      if (
        this.vehicles.find(
          (element) => element.driver_uuid == this.auth.uuid
        ) &&
        !this.is_overrule
      ) {
        this.log.vehicle_uuid = this.vehicles.find(
          (element) => element.driver_uuid == this.auth.uuid
        ).uuid;
        this.changeVehicle();
      }
      if (this.runningLog) {
        this.log = this.$_.cloneDeep(this.runningLog);
      }
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      if (this.is_overrule) {
        this.$router.push({
          name: "PageAllVehicleRunningList",
        });
      } else {
        this.$router.push({
          name: "PageMyVehicleRunningList",
        });
      }
    },
    previewImage() {
      if (this.image) {
        let reader = new FileReader();
        reader.readAsDataURL(this.image);
        reader.onload = () => {
          let img = reader.result;
          let type = img.substring(
            "data:image/".length,
            img.indexOf(";base64")
          );
          compress(reader.result, {
            width: 400,
            type: "image/" + type, // default
            max: 200, // max size
            min: 20, // min size
            quality: 0.8,
          }).then((result) => {
            this.log.image_binary_file = result;
            this.log.image_meta = this.image.lastModifiedDate;
          });
        };
      } else {
        this.log.image_binary_file = null;
      }
    },
    async addVehicleRunningX() {
      if (
        this.$refs.returnForm.validate() &&
        this.$refs.dispatchForm.validate()
      ) {
        if (this.isPreviewed) {
          this.saveLog();
          this.log.uuid = uuidv4();
          this.log.projects = this.projects.find(
            (project) => project.uuid == this.log.project_uuid
          );
          this.log.project_ledgers = this.ledgers.find(
            (ledger) => ledger.uuid == this.log.project_ledger_uuid
          );
          this.log.vehicles = this.vehicles.find(
            (vehicle) => vehicle.uuid == this.log.vehicle_uuid
          );
          this.log.check_list = JSON.stringify(this.log.check_list);
          if (!this.is_overrule) {
            this.log.driver_uuid = this.auth.uuid;
          }
          this.log.staff = this.drivers.find(
            (driver) => driver.uuid == this.log.driver_uuid
          );

          if (this.editable) {
            this.log.edit_status = "ENABLED";
          }

          this.loading = true;
          let addVehicleRunningReturn = await addVehicleRunning(
            this.log.vehicle_uuid,
            this.log,
            this.log.driver_uuid != this.auth.uuid
          );
          this.loading = false;
          if (addVehicleRunningReturn == "success") {
            this.$store.dispatch("clearRunningLog");
            this.goBack();
          }
        } else {
          this.isPreviewed = true;
          this.step = 1;
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },
    changeProjcet() {
      if (this.log.project_uuid)
        this.ledgers = this.projects.find(
          (obj) => obj.uuid === this.log.project_uuid
        ).project_ledgers;
      else this.ledgers = [];
    },
    changeVehicle() {
      if (this.log.vehicle_uuid) {
        this.selectedVehicle = this.vehicles.find(
          (obj) => obj.uuid === this.log.vehicle_uuid
        );
        this.log.dispatch_km = this.current_meter_reading =
          this.selectedVehicle.current_meter_reading;
      } else {
        this.log.dispatch_km = 0;
        this.selectedVehicle = {};
      }
    },
    changeDriver() {
      if (
        this.vehicles.find(
          (element) => element.driver_uuid == this.log.driver_uuid
        )
      ) {
        this.log.vehicle_uuid = this.vehicles.find(
          (element) => element.driver_uuid == this.log.driver_uuid
        ).uuid;
        this.changeVehicle();
      }
    },
    saveLog() {
      this.log.saved_at = new Date();
      let tempLog = this.$_.cloneDeep(this.log);
      this.$store.commit("saveRunningLog", tempLog);
    },
  },
  updated() {
    this.log.total_km = this.$_calculateMeterDiffrence(
      this.log.return_km,
      this.log.dispatch_km
    );
  },
};
</script>